header {
    justify-content: space-between;
    align-items: flex-end;
    display: flex;
    padding: 24px 15px 28px 15px;
}

.se-logo {
    img {
        height: 72px;
    }
}

.se-offer {
    text-align: end;
}
