@import "./Variables.scss";

.ue-container {
    margin: 15px 0px;
    background-color: white;

    padding-bottom: 15px;
    border-bottom: 1px solid black;

    .ue-logos {
        display: flex;
        justify-content: space-around;
        align-items: center;

        .prow { 
            height: 86px;
            
            @media (max-width: $grid-breakpoint-sm) {
                height: 56px;
            }
        }
    }

    img {
        height: 70px;

        @media (max-width: $grid-breakpoint-sm) {
            height: 40px;
        }
    }
}



.ue-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1rem;

    p {
        text-align: center;
        padding: 0;
        margin: 0;
        font-size: 0.9rem;
    }
}
