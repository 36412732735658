@import "./Variables.scss";

footer {
  padding-top: 34px;
}

.dark {
  background-image: linear-gradient(to left, #c1dfc4 0%, #deecdd 100%);
}

.container {
  position: relative;
}

.promotion {
  position: absolute;
  width: 140px;
  height: 140px;
  border-radius: 70px;
  left: 300px;
  bottom: 24px;
  background: white;
  color: var(--bs-primary);
  z-index: 1;

  @media (max-width: $grid-breakpoint-sm) {
    left: 205px;
  }

  .promotion-content {
    position: relative;
  }

  .promotion-heading {
    text-transform: uppercase;
    font-weight: 100;
    position: absolute;
    width: 100%;
    text-align: center;
    top: 0.75rem;
    line-height: 1rem;
    color: black;
  }

  .promotion-discount {
    position: absolute;
    font-size: 2rem;
    font-weight: bold;
    width: 100%;
    text-align: center;
    top: 2.5rem;
  }

  .promotion-info {
    position: absolute;
    font-size: 0.7rem;
    width: 100%;
    text-align: center;
    top: 5.25rem;
    color: black;
  }
}

ul,
il {
  list-style-type: none;
  margin: 1rem 0;
  padding: 0;
}

.se-hero {
  position: relative;
  z-index: 10;

  padding: 4rem 0 4rem;

  @media (min-width: $grid-breakpoint-md) {
    padding: 4rem 4rem;
  }

  .bi-geo-alt {
    color: #717171;
    padding-right: 0.25rem;
  }

  .bi-telephone {
    padding-right: 0.25rem;
  }

  .subtitle {
    margin-top: 34px;
  }

  &__location {
    position: relative;
    z-index: 10;
    font-weight: 100;
  }
}

.categories-section {
  h3 {
    display: flex;
    justify-content: center;
    padding: 34px 0;
  }

  .categories-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

.category-container {
  flex: 1 1 0;
  display: flex;
  flex-direction: row;
  padding: 8px;
  border: var(--bs-primary) 2px solid;
  margin: 1rem;
  min-width: 300px;

  .icon-topic {
    width: 45px;
    margin: 15px;
  }

  .icon-arrow {
    width: 1.25rem;
    margin: 0 1rem;
    color: var(--bs-primary);
  }

  .category-text {
    padding-top: 1rem;
    padding-left: 1rem;

    h5 {
      font-weight: 400;
    }
  }
}
