html {
    --font-size-sm: 0.75rem; /* 12px */
    --font-size-base: 1rem; /* 16px, base */
    --font-size-md: 1.25rem; /* 20px */
    --font-size-lg: 1.5rem; /* 24px */
    --font-size-xl: 1.75rem; /* 28px */
    --font-size-xxl: 2rem; /* 32px */
    --font-size-xxxl: 2.25rem; /* 36px */
}

h1 {
    font-size: var(--font-size-xxxl) !important;
}

h2 {
    font-size: var(--font-size-md) !important;
    font-weight: 400 !important;
    margin: 0px !important;
}

h3 {
    font-size: var(--font-size-md) !important;
}

h4 {
    font-size: var(--font-size-base) !important;
}

h5 {
    font-size: var(--font-size-base) !important;
}

h6 {
    font-size: var(--font-size-md) !important;
}

:root {
    --bs-primary: #0b7a75 !important;
}

.btn {
    --bs-btn-bg: var(--bs-primary) !important;
    --bs-btn-color: #fff !important;
    --bs-btn-border-radius: 0px !important;
    --bs-btn-border-width: 2px !important;
    --bs-btn-border-color: var(--bs-primary) !important;
    --bs-btn-hover-color: #fff !important;
    --bs-btn-hover-bg: #0b7a75 !important;
    --bs-btn-hover-border-color: #0b7a75 !important;
    --bs-btn-focus-shadow-rgb: 49, 132, 253 !important;
    --bs-btn-active-color: #fff !important;
    --bs-btn-active-bg: #0b7a75 !important;
    --bs-btn-active-border-color: #0b7a75 !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
    --bs-btn-disabled-color: #fff !important;
    --bs-btn-disabled-bg: var(--bs-primary) !important;
    --bs-btn-disabled-border-color: var(--bs-primary) !important;
}

@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-icons/font/bootstrap-icons.scss";
